.terms {
    width: 100%;
    background-color: rgb(9, 9, 24);
    display: block;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.term {
    padding-top: 100px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 50px;
    color: black;
    margin: 0 auto;
    background-color: white;
    width: 1000px;
    height: 100%;
    border-radius: 10px;
}

.personal {
    padding-top: 120px;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 100px;
    color: black;
    margin: 0 auto;
    background-color: white;
    width: 1000px;
    height: 100%;
    border-radius: 10px;
}

.topblock {
    margin: 0 auto;
    background-color: rgb(9, 9, 24);
    width: 1000px;
    height: 100px;
}

.topterm {
    font-weight: 800;
    font-size: 1.4rem;;
}

.middleterm {
    font-weight: 800;
    font-size: 1rem;;
}