
@media (max-width: 767px){
    .hero {
        display: none;
    }

    .video {
        display: none;
    }

    .video-cover {
        display: none;
    }

    .mobbg {
        display: flex;
        width: 100%;
        height: 80vh;
    }

    .mobbgg {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .hero {
        display: none;
    }

    .video {
        display: none;
    }

    .video-cover {
        display: none;
    }

    .mobbg {
        display: flex;
        width: 100%;
        height: 90vh;
    }

    .mobbgg {
        width: 100%;
        height: 100%;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
    .hero {
        display: none;
    }

    .video {
        display: none;
    }

    .video-cover {
        display: none;
    }

    .mobbg {
        display: flex;
        width: 100%;
        height: 100vh;
    }

    .mobbgg {
        width: 100%;
        height: 100%;
    }

}

@media (min-width: 1200px) {

.video-cover {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(250, 255, 204, 0.1);                                                                 
    z-index:-4;
}

.video {
    position: fixed;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    z-index: -5;
    top: 0;
}


.hero {
    height: 100vh;
    width: 1920px;
    object-fit: contain;
}

.mobbg {
    display: none;
}

}