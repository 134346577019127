

.asd {
  width: 100%;
  height: 1400px;
  position: absolute;
  background:  radial-gradient(
    circle,
    rgb(18, 24, 80) 7%,
    rgb(15, 25, 58) 20%,
    rgb(14, 24, 54) 30%,
    rgb(9, 9, 24) 70%
  );
  display: block;
  justify-content: center;
  align-items: center;
}



.airdesti {
  width: 400px;
  height: 40px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 230px;
  font-size: 1.2rem;
}

.airinco {
  width: 400px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 230px;
  font-size: 1.2rem;
}

.airdanger {
  width: 400px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 230px;
  font-size: 1.2rem;
}



.addingzone {
  margin-top: 90px;
  margin-left: 15px;
  height: 380px;
  width: 575px;
  display: flex;
  text-align: left;
  position: absolute;
  color: black;
}

.boxconone {
  position: absolute;
  color: black;
  font-size: 1rem;
  font-weight:bold;
  margin-top: 90px;
  margin-left: 38px;
  width: 200px;
}

.boxcontwo {
  position: absolute;
  color: black;
  font-size: 1rem;
  font-weight:bold;
  margin-top: 90px;
  margin-left: 300px;
  width: 200px;
}

.boxconthree {
  position: absolute;
  color: black;
  font-size: 1rem;
  font-weight:bold;
  margin-top: 222px;
  margin-left: 38px;
  width: 240px;
}

.smallcon {
  position: absolute;
  color: rgb(58, 58, 58);
  font-size: 0.9rem;
  width: 250px;
  margin-top: 222px;
  margin-left: 145px;
}

.smallcons {
  position: absolute;
  color: rgb(161, 161, 161);
  font-size: 1rem;
  margin-top: 145px;
  margin-left: 520px;
}

.boxconfour {
  position: absolute;
  color: rgb(161, 161, 161);
  font-size: 1rem;
  margin-top: 275px;
  margin-left: 480px;
}

.xone {
  position: absolute;
  font-size: 1rem;
  color: rgb(161, 161, 161);
  margin-top: 278px;
  margin-left: 165px;
}

.xtwo {
  position: absolute;
  font-size: 1rem;
  color: rgb(161, 161, 161);
  margin-top: 278px;
  margin-left: 321px;
}

.airqty {
  position: absolute;
  width: 200px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 130px;
  font-size: 1.2rem;
}

.airkg {
  position: absolute;
  width: 200px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 300px;
  margin-top: 130px;
  font-size: 1.2rem;
}

.airone {
  position: absolute;
  width: 100px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 260px;
  font-size: 1.2rem;
}

.airtwo {
  position: absolute;
  width: 100px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 195px;
  margin-top: 260px;
  font-size: 1.2rem;
}

.airthree {
  position: absolute;
  width: 100px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 350px;
  margin-top: 260px;
  font-size: 1.2rem;
}

.continent {
  width: 400px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 200px;
  font-size: 1.2rem;
}

.nation {
  width: 400px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 20px;
  font-size: 1.2rem;
}

.city {
  width: 400px;
  height: 44px;
  background-color: #f8f9ff;
  border: 1px solid #e4e5ed;
  border-radius: 6px;
  margin-left: 38px;
  margin-top: 20px;
  font-size: 1.2rem;
}

.noticd {
  position: absolute;
  width: 500px;
  color: black;
  font-size: 2.4rem;
  font-weight: 600;
  margin-top: 210px;
  margin-left: 50px;
}

.noticdd {
  position: absolute;
  width: 500px;
  color: black;
  font-size: 2.6rem;
  font-weight: 600;
  margin-top: 160px;
  margin-left: 35px;
}

.noticds {
  position: absolute;
  width: 500px;
  color: black;
  font-size: 1.5rem;
  font-weight: 600;
  margin-top: 230px;
  margin-left: 38px;
}

.noticbtn {
  font-size: 1.3rem;
  text-align: center;
  position: absolute;
  margin-left: 180px;
  margin-top: 300px;
  height: 50px;
  line-height: 50px;
  width: 210px;
  border: none;
  cursor: pointer;
  color: white;
  background-color: #0d4ba6;
  border-radius: 5px;
}

.wall {
  position: absolute;
  width: 500px;
  height: 50px;
  color: black;
  font-size: 2.4rem;
  font-weight: 600;
  margin-left: 25px;
  background-color: white;
  z-index: 5;
}

.walls {
  position: absolute;
  width: 500px;
  height: 50px;
  color: black;
  font-size: 2.4rem;
  font-weight: 600;
  margin-left: 25px;
  background-color: white;
  z-index: 5;
}

.noticbtn:hover {
    background-color: #5281c7;
}

.explaines {
  position: absolute;
  background-image: url('../assets/물음표.svg');
  cursor: pointer;
  margin-top: 64px;
  margin-left: 482px;
  width: 22px;
  height: 22px;
}

.targets {
  position: absolute;
  background-color: #f8f9ff;
  width: 500px;
  height: 465px;
  margin-left: 48px;
  margin-top: 100px;
  border: 1px solid #e4e5ed;
  box-shadow: 1px 1px 1px 1px grey;
  border-radius: 8px;
  z-index: 3;
  display: none;
  color: black;

}

.explaines:hover ~ .targets {
  display: block;
 }

 

@media (max-width: 767px){

  .introcona{
    padding-top: 40px;
    width: 360px;
    margin: 0 auto;
  }

  .twopart{
    display: block;
  }

  .twopart input{
    width: 380px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .introcona{
    width: 600px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 270px;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .introcona{
    width: 800px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 360px;
  }


}

@media (min-width: 1200px) {
  .introcona{
    width: 1000px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 440px;
  }
}