

body {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
}


.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

@keyframes fade-left {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}



@media (max-width: 767px){
  .wrape {
    display: none;
  }
  
  .rollimgs {
    height: 350px;
  }
  
  .slaonep {
    min-height: 350px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_01.jpg');
    background-size: cover
  }
  
  .slatwop {
    min-height: 350px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_02.jpg');
    background-size: cover
  }
  
  .slathreep {
    min-height: 350px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_03.jpg');
    background-size: cover
  }
  
  .slafourp {
    min-height: 350px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_04.jpg');
    background-size: cover
  }
  
  .slaonepp {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: rgba(36, 36, 36, 0.1);
  }

  .slaonepp h3{
    padding-left: 10px;
    font-size: 1.8rem;
  }

  
  .slaonepp p{
    font-size: 1rem;
    width: 400px;
    padding-left: 10px;
  }
  
  .slick-slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .slick-list {
    height: 100%;
  }
  
  .slick-slide > div {
    height: 100%;
  }
  
}



@media (min-width: 768px) and (max-width: 991px) {
  .wrape {
    display: none;
  }
  
  .rollimgs {
    height: 470px;
  }
  
  .slaonep {
    min-height: 470px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_01.jpg');
    background-size: cover
  }
  
  .slatwop {
    min-height: 470px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_02.jpg');
    background-size: cover
  }
  
  .slathreep {
    min-height: 470px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_03.jpg');
    background-size: cover
  }
  
  .slafourp {
    min-height: 470px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_04.jpg');
    background-size: cover
  }
  
  .slaonepp {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: rgba(36, 36, 36, 0.1);
  }

  .slaonepp h3{
    padding-left: 30px;
    font-size: 2rem;
  }

  
  .slaonepp p{
    font-size: 1.2rem;
    width: 480px;
    padding-left: 30px;
  }
  
  
  .slick-slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .slick-list {
    height: 100%;
  }
  
  .slick-slide > div {
    height: 100%;
  }
  
}

@media (min-width: 992px) and (max-width: 1199px) {
  .wrape {
    display: none;
  }
  
  .rollimgs {
    height: 570px;
  }
  
  .slaonep {
    min-height: 570px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_01.jpg');
    background-size: cover
  }
  
  .slatwop {
    min-height: 570px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_02.jpg');
    background-size: cover
  }
  
  .slathreep {
    min-height: 570px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_03.jpg');
    background-size: cover
  }
  
  .slafourp {
    min-height: 570px;
    display: block;
    height: 100%;
    margin: 0 auto;
    background-image: url('../assets/rot_04.jpg');
    background-size: cover
  }
  
  .slaonepp {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background-color: rgba(36, 36, 36, 0.1);
  }

  .slaonepp h3{
    padding-left: 60px;
    font-size: 2.4rem;
  }

  
  .slaonepp p{
    font-size: 1.4rem;
    width: 560px;
    padding-left: 60px;
  }
  
  .slick-slider {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .slick-list {
    height: 100%;
  }
  
  .slick-slide > div {
    height: 100%;
  }
  
}

@media (min-width: 1200px) {

  .rollimgs {
    display: none;
  }

.eee {
  margin-left: 40px;
  margin-bottom: 40px;
}

.slionecon {
  margin-top: 320px;
  margin-bottom: 10px;
  font-size: 2rem;
}


.wrape {
  width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(9, 9, 24);
    
}

.swiper {
  --slide-size: 50vmin;
  --slide-margin: 4vmin;
  height: var(--slide-size);
  margin: 0 auto;
  position: relative;
  padding-top: 173px;
  padding-bottom: 150px;
  width: 100%;
}

.swrap {
  display: flex;
  margin: 0 calc(var(--slide-margin) * -1);
  position: absolute;
  transition: transform var(--base-duration) cubic-bezier(0.25, 1, 0.35, 1);
}

.lefte {
  position: absolute;
  backdrop-filter: blur(10px);    
  width: 999px;
  height: 495px;
  margin-right: 2000px;
  padding-top: 173px;
  z-index: 1;
}

.righte {
  position: absolute;
  backdrop-filter: blur(5px);        
  width: 999px;
  height: 495px;
  margin-left: 2000px;
  padding-top: 173px;
  z-index: 1;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  align-items: center;
  flex-direction: column;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 0;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.test1 {
  width: 800px;
  height: 450px;
  background: url('../assets/rot_01.jpg');
  text-align: left;
  color: rgb(255, 255, 255);
  border-radius: 15px;
}
.test2 {
  width: 800px;
  height: 450px;
    background: url('../assets/rot_02.jpg');
    text-align: left;
    color: rgb(255, 255, 255);
    border-radius: 15px;
}
.test3 {
  width: 800px;
  height: 450px;
    background: url('../assets/rot_03.jpg');
    text-align: left;
    color: rgb(255, 255, 255);
    border-radius: 15px;
}
.test4 {
  width: 800px;
  height: 450px;
    background: url('../assets/rot_04.jpg');  
    text-align: left;
    color: rgb(255, 255, 255);
    border-radius: 15px;
}

.swiper .swiper-pagination {
  position: absolute;
}

.swiper-pagination-bullet {
  margin: -10px 40px !important;
  color: #3848e7;
  width: 90px;
  height: 90px;
  font-size: 0;
  opacity: 1;
}

.swiper-pagination-bullet:nth-child(1){
  background-size: 90px 90px;
  background-image:url("../assets/01_off.png");
}
.swiper-pagination-bullet:nth-child(1):hover{
  background-image:url("../assets/01_on.gif");
  color: #fff;
}

.swiper-pagination-bullet:nth-child(2){
    background-size: 90px 90px;
    background-image:url("../assets/02_off.png");
}
.swiper-pagination-bullet:nth-child(2):hover{
    background-image:url("../assets/02_on.gif");
    color: #fff;
}

.swiper-pagination-bullet:nth-child(3){
    background-size: 90px 90px;
    background-image:url("../assets/03_off.png");
  }
  .swiper-pagination-bullet:nth-child(3):hover{
    background-image:url("../assets/03_on.gif");
    color: #fff;
  }
  
  .swiper-pagination-bullet:nth-child(4){
      background-size: 90px 90px;
      background-image:url("../assets/04_off.png");
  }
  .swiper-pagination-bullet:nth-child(4):hover{
    background-image:url("../assets/04_on.gif");
    color: #fff;
  }

button.swiper-pagination-bullet:nth-child(1):focus {
  background-image:url("../assets/04_on.gif");
}

button.swiper-pagination-bullet:nth-child(2):focus {
  background-image:url("../assets/02_on.gif");
}

button.swiper-pagination-bullet:nth-child(3):focus {
  background-image:url("../assets/03_on.gif");
}

button.swiper-pagination-bullet:nth-child(4):focus {
  background-image:url("../assets/04_on.gif");
}


.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}

.active.fade-left {
  animation: fade-left 1s ease-in;
}

@keyframes fade-left {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}


}