
@media (max-width: 767px){
    .fheader{
        display: none;
      }

    .contentsss {
        width: 100%;
        max-width: 100%;
        position: absolute;
        z-index: 10;
        margin-top: 200px;
        font-size: 1.6rem;
    }

    .contentsss h1 {
        margin-left: 30px;
    }

    .btnsss {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .fheader{
        display: none;
      }

    .contentsss {
        width: 100%;
        max-width: 100%;
        position: absolute;
        z-index: 10;
        margin-top: 450px;
        font-size: 1.7rem;
    }

    .contentsss h1 {
        margin-left: 90px;
    }

    .btnsss {
        display: none;
    }

}

@media (min-width: 992px) and (max-width: 1199px) {
    .contentsss {
        width: 100%;
        max-width: 100%;
        position: absolute;
        z-index: 10;
        margin-top: 500px;
        font-size: 1.8rem;
    }

    .contentsss h1 {
        margin-left: 120px;
    }

    .btnsss {
        display: none;
    }

}

@media (min-width: 1200px) {
    
.area2 {
    width: 1000px;
    margin: 0 auto;
}

.area4 {
    width: 1000px;
    margin: 0 auto;
}


.contentsss {
    width: 1000px;
    min-width: 1000px;
    height: 600px;
    margin: 0 auto;
    position: absolute;
    z-index:0;
    left: 50%;
    margin-left: -500px;
    top: 45vh;
    z-index: 4;
}

.contentsss h1 {
    font-size: 4rem;
    color: white;
    font-family:Arial, Helvetica, sans-serif;
}

.contentsss p {
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    color: white;
}

.contentsss .btnsss {
    margin: 1rem .2rem;
    width: 125PX;
    padding: 12px 32px;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: transparent;
    color: white;
    border: 1px solid white;
    cursor: pointer;
}

}


.fheader{
    width: 100%;
  }
  
  .fheader-family{
    height: 42px;
    display: flex;
    background-color: rgb(247, 247, 247);
  }
  
  .fheader-family-logo{
    width: 41px;
    height: 42px;
    background-image: url('../assets/fa_00.svg');
    background-size: cover;
  }
  
  .fheader-family-listA{
    width: 90px;
    height: 42px;
    background-image: url('../assets/fa_01.svg');
    background-size: cover;
  }
  
  .fheader-family-listB{
    width: 90px;
    height: 42px;
    background-image: url('../assets/fa_02.svg');
    background-size: cover;
  }
  
  .fheader-family-listC{
    width: 90px;
    height: 42px;
    background-image: url('../assets/fa_03.svg');
    background-size: cover;
  }
  
  .fheader-family-listD{
    width: 90px;
    height: 42px;
    background-image: url('../assets/fa_04.svg');
    background-size: cover;
  }