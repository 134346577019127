
.aiii {
  width: 100%;
  height: 1400px;
  position: absolute;
  background:  radial-gradient(
    circle,
    rgb(18, 24, 80) 7%,
    rgb(15, 25, 58) 20%,
    rgb(14, 24, 54) 30%,
    rgb(9, 9, 24) 70%
  );
  display: block;
  justify-content: center;
  align-items: center;
  }
  

  
  .aform {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 51%;

  }
  
  .aform-container {
    position: absolute;
    margin-left: 400px;
    width: 600px;
    height: 680px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
    display: flex;
  }
  
  .aform-container .aheader {
    margin-top: 100px;
    margin-left: 35px;
    display: flex;
    text-align: left;
    position: absolute;
    color: black;
  }
  
  .aform-container .abody {
    position: absolute;
    margin-top: 64px;
  }

  .fcldesti {
    width: 400px;
    height: 40px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }

  .fclinco {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }
  
  .fcldanger {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }

  .fcltypedry {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: #f8f9ff;
    background-image: url('../assets/1_dry.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 110px;
    margin-left: 70px;
  }

  .fcltypereefer {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: #f8f9ff;
    background-image: url('../assets/2_reefer.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 110px;
    margin-left: 320px;
  }

  .fcltypeopen {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: #f8f9ff;
    background-image: url('../assets/3_opentop.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 302px;
    margin-left: 70px;
  }

  .fcltypeflat {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: #f8f9ff;
    background-image: url('../assets/4_flatrack.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 302px;
    margin-left: 320px;
  }

  .fcltypedry:hover {
    border: 1px solid #4394ff;
    transition: 0.3s;
  }

  .fcltypereefer:hover {
    border: 1px solid #4394ff;
    transition: 0.3s;
  }

  .fcltypeopen:hover {
    border: 1px solid #4394ff;
    transition: 0.3s;
  }

  .fcltypeflat:hover {
    border: 1px solid #4394ff;
    transition: 0.3s;
  }

  
  .fcltypedry:hover ~ .DRY {
    color: #4394ff;
    transition: 0.3s;
  }

  .fcltypereefer:hover ~ .REEFER {
    color: #4394ff;
    transition: 0.3s;
  }

  .fcltypeopen:hover ~ .OPEN {
    color: #4394ff;
    transition: 0.3s;
  }

  .fcltypeflat:hover ~ .FLAT {
    color: #4394ff;
    transition: 0.3s;
  }

  .DRY {
    position: absolute;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 270px;
    margin-left: 150px;
  }

  .REEFER {
    position: absolute;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 270px;
    margin-left: 380px;
  }

  .OPEN {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 462px;
    margin-left: 124px;
  }

  .FLAT {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 462px;
    margin-left: 368px;
  }


  .explaine {
    position: absolute;
    background-image: url('../assets/물음표.svg');
    cursor: pointer;
    margin-top: 64px;
    margin-left: 422px;
    width: 22px;
    height: 22px;
  }

  .target {
    position: absolute;
    background-color: #f8f9ff;
    width: 500px;
    height: 570px;
    margin-left: 48px;
    margin-top: 100px;
    border: 1px solid #e4e5ed;
    box-shadow: 1px 1px 1px 1px grey;
    border-radius: 8px;
    z-index: 3;
    display: none;
    color: black;

  }

  .explaine:hover ~ .target {
    display: block;
   }

  .namebold {
    padding-top: 25px;
    padding-left: 20px;
    font-size: 1rem;
    font-weight: 600;
  }

  .explainer {
    padding-left: 20px;
  }

  
  .explainesss {
    position: absolute;
    background-image: url('../assets/물음표.svg');
    cursor: pointer;
    margin-top: 64px;
    margin-left: 406px;
    width: 22px;
    height: 22px;
  }

  .targetsss {
    position: absolute;
    background-color: #f8f9ff;
    width: 500px;
    height: 185px;
    margin-left: 48px;
    margin-top: 100px;
    border: 1px solid #e4e5ed;
    box-shadow: 1px 1px 1px 1px grey;
    border-radius: 8px;
    z-index: 3;
    display: none;
    color: black;

  }

  .explainesss:hover ~ .targetsss {
    display: block;
   }

   
  .explainessss {
    position: absolute;
    background-image: url('../assets/물음표.svg');
    cursor: pointer;
    margin-top: 64px;
    margin-left: 450px;
    width: 22px;
    height: 22px;
  }

  .targetssss {
    position: absolute;
    background-color: #f8f9ff;
    width: 500px;
    height: 315px;
    margin-left: 48px;
    margin-top: 100px;
    border: 1px solid #e4e5ed;
    box-shadow: 1px 1px 1px 1px grey;
    border-radius: 8px;
    z-index: 3;
    display: none;
    color: black;

  }

  
  .targetssssd {
    position: absolute;
    background-color: #f8f9ff;
    width: 500px;
    height: 245px;
    margin-left: 48px;
    margin-top: 100px;
    border: 1px solid #e4e5ed;
    box-shadow: 1px 1px 1px 1px grey;
    border-radius: 8px;
    z-index: 3;
    display: none;
    color: black;

  }

  .explainessss:hover ~ .targetssss {
    display: block;
   }

   .explainessss:hover ~ .targetssssd {
    display: block;
   }

  .fclsize {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }

  .fclqty {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }

  .notecome {
    width: 510px;
    height: 200px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 200px;
    font-size: 1.2rem;
    font-family:Arial, Helvetica, sans-serif;
  }



  .yes {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: #f8f9ff;
    background-image: url('../assets/amzz.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 200px;
    margin-left: 70px;
  }
  

  .no {
    position: absolute;
    width: 200px;
    height: 150px;
    background-color: #f8f9ff;
    background-image: url('../assets/otherr.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 200px;
    margin-left: 320px;
  }


  .yes:hover {
    background-image: url('../assets/amz.png');
    border: 1px solid #4394ff;
    transition: 0.3s;
  }

  .no:hover {
    background-image: url('../assets/other.png');
    border: 1px solid #4394ff;
    transition: 0.3s;
  }



  .yes:hover ~ .YES {
    color: #4394ff;
    transition: 0.3s;
  }

  .no:hover ~ .NO {
    color: #4394ff;
    transition: 0.3s;
  }

    .YES {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 370px;
    margin-left: 75px;
  }

  .NO {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 370px;
    margin-left: 325px;
  }


  
  /* PROGRESS BAR */
  .progressbar {
    margin-left: 350px;
    margin-bottom: 530px;
    position: absolute;
    width: 460px;
    height: 5px;
    background-color: #f2f2f2;
    z-index: 5;
  }
  
  .progressbar div {
    width: 33.3%;
    height: 100%;
    background-color: #0d4ba6;
    border-radius: 9px;
    transition: 0.5s;
  }
  


  .alertf {
    position: absolute;
    margin-left: 39px;
    color: red;
    font-size: 0.9rem;
    width: 300px;
    height: 60px;
    display: none;
    z-index: 5;
  }

  .nextbtnsf {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-top: 222px;
    margin-left: 430px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #e27b4c;
    border-radius: 5px;
  }

  .nextbtnsf:hover {
    background-color: #e2a385;
  }

  .nextbtnsf:disabled {
    background-color: #e2a385;
  }

  .nextbtnsf:disabled ~ .alertf {
    display: block;
  }


  
@media (max-width: 767px){

  .introcona{
    padding-top: 40px;
    width: 360px;
    margin: 0 auto;
  }

  .twopart{
    display: block;
  }

  .twopart input{
    width: 380px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .introcona{
    width: 600px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 270px;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .introcona{
    width: 800px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 360px;
  }


}

@media (min-width: 1200px) {
  .introcona{
    width: 1000px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 440px;
  }
}