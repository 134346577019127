.asssdd {
  width: 100%;
  height: 1400px;
  position: absolute;
  background:  radial-gradient(
    circle,
    rgb(18, 24, 80) 7%,
    rgb(15, 25, 58) 20%,
    rgb(14, 24, 54) 30%,
    rgb(9, 9, 24) 70%
  );
  display: block;
  justify-content: center;
  align-items: center;
  }
  

  
  .aform {
    width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
  }
  
  
  .lcldesti {
    width: 400px;
    height: 40px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }

  .lclinco {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }
  
  .lcldanger {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }

  .lcltype {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }

  .lcldouble {
    width: 400px;
    height: 44px;
    background-color: #f8f9ff;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    margin-left: 38px;
    margin-top: 230px;
    font-size: 1.2rem;
  }


  .introcon {
    width: 1000px;
    height: 100%;
    left: 50%;
    margin-left: -500px;
    position: absolute;
    z-index: 2;
  }
  
  .onetell {
    width: 350px;
    position: absolute;
    top: 25%;
  }

  .titl {
    font-size: 2.8rem;
    color: #b2d3ff;
  }
  
  .cons {
    font-size: 1.3rem;
  }
  
  .progres {
    position: absolute;
    font-size: 1.2rem;
    color: #0d4ba6;
    margin-left: 520px;
    margin-bottom: 500px;
  }
  
  
  .btnwrap {
    position: absolute;
    margin-top: 500px;
  }

  .btnwraps {
    position: absolute;
  }
  
  .btnwrapss {
    position: absolute;
    margin-top: 90px;
  }

  .nextbtn {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 430px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }
  
  .prevbtn {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 45px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }

  .prevbtn1 {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 45px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }

  .prevbtn2 {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 45px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }
  
  .prevbtn3 {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 45px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }
  
  .prevbtn4 {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 45px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }

  .nextbtns {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-top: 222px;
    margin-left: 430px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #e27b4c;
    border-radius: 5px;
  }

  .nextbtnsa {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 430px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #e27b4c;
    border-radius: 5px;
  }


  .nextbtny {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 240px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }

  
  .prevbtns {
    font-size: 1.3rem;
    text-align: center;
    position: absolute;
    margin-left: 45px;
    height: 50px;
    line-height: 50px;
    width: 130px;
    border: none;
    cursor: pointer;
    color: white;
    background-color: #0d4ba6;
    border-radius: 5px;
  }
  
  .nextbtn:hover {
    background-color: #5281c7;
  }

  .nextbtn:disabled {
    background-color: #5281c7;
  }
  
  .prevbtn:hover {
    background-color: #5281c7;
  }

  .prevbtn:disabled {
    background-color: #5281c7;
  }

  .prevbtn1:disabled {
    background-color: #5281c7;
  }

  .prevbtn2:disabled {
    background-color: #5281c7;
  }

  .prevbtn3:disabled {
    background-color: #5281c7;
  }

  .prevbtn4:disabled {
    background-color: #5281c7;
  }
  
  .nextbtns:hover {
    background-color: #e2a385;
  }

  .nextbtnsa:hover {
    background-color: #e2a385;
  }

  .nextbtnsa:disabled {
    background-color: #e2a385;
  }



  .nextbtny:hover {
    background-color: #5281c7;
  }

  .nextbtny:disabled {
    background-color: #5281c7;
  }
  
  .prevbtns:hover {
    background-color: #5281c7;
  }
  
  input:disabled {
    background-color: #858585;
  }
  
  .prevbtn:focus {
    outline: none;
  }
  
  .nextbtn:focus {
    outline: none;
  }
  
  
  
  .submitform:hover {
    background-color: #5281c7;
  }
  
  .submitforms:hover {
    background-color: #5281c7;
  }
  

  
  .lcltypeboxq {
    position: absolute;
    width: 200px;
    height: 140px;
    background-image: url('../assets/boxe.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    margin-top: 110px;
    margin-left: 70px;
  }

  .lcltypepalletq {
    position: absolute;
    width: 200px;
    height: 140px;
    background-image: url('../assets/parre.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    margin-top: 110px;
    margin-left: 320px;
  }

  .lcltypesackq {
    position: absolute;
    width: 200px;
    height: 140px;
    background-image: url('../assets/sacke.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    margin-top: 300px;
    margin-left: 70px;
  }

  .lcltypeotherq {
    position: absolute;
    width: 200px;
    height: 140px;
    background-image: url('../assets/others.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    margin-top: 300px;
    margin-left: 320px;
  }
  
  .lcltypebox {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f8f9ff;
    background-image: url('../assets/boxe.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 262px;
    margin-left: 125px;
  }

  .lcltypepallet {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f8f9ff;
    background-image: url('../assets/parre.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 262px;
    margin-left: 365px;
  }

  .lcltypesack {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f8f9ff;
    background-image: url('../assets/sacke.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 452px;
    margin-left: 115px;
  }

  .lcltypeother {
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #f8f9ff;
    background-image: url('../assets/others.png');
    background-size: 200px 150px;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 452px;
    margin-left: 380px;
  }

  .lcltypebox:hover {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  .lcltypepallet:hover {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  .lcltypesack:hover {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  .lcltypeother:hover {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  
  .lcltypebox:hover ~ .BOX {
    color: #4394ff;
    transition: 0.3s;
  }

  .lcltypepallet:hover ~ .PALLET {
    color: #4394ff;
    transition: 0.3s;
  }

  .lcltypesack:hover ~ .SACK {
    color: #4394ff;
    transition: 0.3s;
  }

  .lcltypeother:hover ~ .OTHER {
    color: #4394ff;
    transition: 0.3s;
  }

  .lcltypebox:checked {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  .lcltypepallet:checked {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  .lcltypesack:checked {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  .lcltypeother:checked {
    border: 1px solid #4394ff;;
    transition: 0.3s;
  }

  
  .lcltypebox:checked ~ .BOX {
    color: #4394ff;
    transition: 0.3s;
  }

  .lcltypepallet:checked ~ .PALLET {
    color: #4394ff;
    transition: 0.3s;
  }

  .lcltypesack:checked ~ .SACK {
    color: #4394ff;
    transition: 0.3s;
  }

  .lcltypeother:checked ~ .OTHER {
    color: #4394ff;
    transition: 0.3s;
  }

  


  .BOX {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 260px;
    margin-left: 155px;
  }

  .PALLET {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 260px;
    margin-left: 395px;
  }

  .SACK {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 450px;
    margin-left: 145px;
  }

  .OTHER {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 450px;
    margin-left: 410px;
  }

  .lcldoubleok {
    position: absolute;
    width: 150px;
    height: 140px;
    background-color: #f8f9ff;
    background-image: url('../assets/dange.png');
    background-size: cover;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 190px;
    margin-left: 30px;
  }

  .lcldoubleno {
    position: absolute;
    width: 150px;
    height: 140px;
    background-color: #f8f9ff;
    background-image: url('../assets/danger.png');
    background-size: cover;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 190px;
    margin-left: 220px;
  }

  .lcldoublewhat {
    position: absolute;
    width: 150px;
    height: 140px;
    background-color: #f8f9ff;
    background-image: url('../assets/othere.png');
    background-size: cover;
    border: 1px solid #e4e5ed;
    border-radius: 6px;
    font-size: 0rem;
    cursor: pointer;
    margin-top: 190px;
    margin-left: 410px;
  }

  .DAN {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 360px;
    margin-left: 100px;
  }

  .DANGE {
    position: absolute;
    width: 200px;
    color: black;
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 360px;
    margin-left: 270px;
}

.DANGER {
  position: absolute;
  width: 200px;
  color: black;
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 360px;
  margin-left: 470px;
}


.lcldoubleok:hover {
  border: 1px solid #4394ff;
  transition: 0.3s;
}

.lcldoubleno:hover {
  border: 1px solid #4394ff;
  transition: 0.3s;
}

.lcldoublewhat:hover {
  border: 1px solid #4394ff;
  transition: 0.3s;
}


.lcldoubleok:hover ~ .DAN {
  color: #4394ff;
  transition: 0.3s;
}

.lcldoubleno:hover ~ .DANGE {
  color: #4394ff;
  transition: 0.3s;
}

.lcldoublewhat:hover ~ .DANGER {
  color: #4394ff;
  transition: 0.3s;
}


.explainess {
  position: absolute;
  background-image: url('../assets/물음표.svg');
  cursor: pointer;
  margin-top: 64px;
  margin-left: 412px;
  width: 22px;
  height: 22px;
}

.targetss {
  position: absolute;
  background-color: #f8f9ff;
  width: 500px;
  height: 200px;
  margin-left: 48px;
  margin-top: 100px;
  border: 1px solid #e4e5ed;
  box-shadow: 1px 1px 1px 1px grey;
  border-radius: 8px;
  z-index: 3;
  display: none;
  color: black;

}

.explainess:hover ~ .targetss {
  display: block;
 }


 
@media (max-width: 767px){

  .introcona{
    padding-top: 40px;
    width: 360px;
    margin: 0 auto;
  }

  .twopart{
    display: block;
  }

  .twopart input{
    width: 380px;
  }

}

@media (min-width: 768px) and (max-width: 991px) {
  .introcona{
    width: 600px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 270px;
  }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .introcona{
    width: 800px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 360px;
  }


}

@media (min-width: 1200px) {
  .introcona{
    width: 1000px;
    margin: 0 auto;
  }

  .twopart{
    display: flex;
  }

  .twopart input{
    width: 440px;
  }
}