@font-face {
  font-family: 'Pretendard-Regular';
  src: url('https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

*{
  font-family: 'Pretendard-Regular';
}

.dro {
  width: 100%;
  height: 100%;
  overflow: hidden;
 }

 
@media (max-width: 767px){
  body {
    width: 100%;
    height: 100%;
  }
  

  .totalwraps {
  width: 100%;
  height: 100%;
  }
  
  .fulls {
    width: 100%;
    height: 100%;
  }

  ul {
    list-style-type: none;
  }
  
  .headerbody a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: #b2d3ff;
    transition: 1s;
  }
  
  .btn {
    padding: 12px 32px;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: transparent;
    color: white;
    border: 1px solid white;
    cursor: pointer;
  }
  
  .btn-light {
    background: rgba(255,255,255, .2);
  }
  
  .btn:hover {
    background: rgba(255,255,255, .2);
  }
  
  .app_logo {
    position: absolute;
    cursor: pointer;
    width: 120px;
    height: 90px;
    top: 0;
  }

  .app_logoss {
    position: absolute;
    cursor: pointer;
    width: 40%;
    height: 40%;
    top: 16%;
    left: 0;
  }

  .app_logos {
    position: absolute;
    width: 180px;
    height:150px;
    margin-top: 20px;
    margin-left: 69px;
  }


  .idshow {
    position: absolute;
    cursor: pointer;
    top: 17%;
    left: 30%;
    color: black;
    font-weight: 600;
  }

  .oas {
    position: absolute;
    width: 130px;
    height:35px;
    margin-top: 200px;
    margin-left: 90px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    background-color: #0d4ba6;
    color: white;
  }

  .oas:hover {
    background-color: #5281c7;
  }
  
  
.headerbody {
  width: 100%;
}

.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
}

.header-bg {
    background-color: #011020;
    transition: .5s;
    height: 90px;
}

.heady {
  position: absolute;
  width: 100%;
  height: 90px;
  left: 20px;
}


.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}

.login {
display: none;
}

.loginbtn {
position: absolute;
right: 0;
margin-right: 40px;
color: white;
top: 35%;
font-size: 1.6rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}

.loginbtn:hover {
color: #b2d3ff;
transition: 1s;
}

.mypa {
  display: none;
}

.mypa1 {
display: none;
}

.mypa2 {
  display: none;
}

.mypa3 {
  display: none;
}


.logout {
  display: none;
}

.logout:hover {
color: #b2d3ff;
transition: 1s;
}

.mobnav a {
  color: black;
  font-weight: 600;
  text-decoration: none;
}

.mobnav li {
  list-style: none;
}

.mobbtnpro {
  display: flex;
  position: absolute;
  height: 80px;
  width: 200px;
  right: 14%;
  top: 5%;
  color: black;
  font-size: 1.8rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  .mobbtnair {
  position: absolute;
  top: 17%;
  left: 35%;
  margin-right: 60px;
  color: black;
  font-size: 1.8rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  .mobbtnfcl {
  position: absolute;
  top: 24%;
  left: 35%;
  margin-right: 60px;
  color: black;
  font-size: 1.8rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  .mobbtnlcl {
  position: absolute;
  top: 31%;
  left: 35%;
  margin-right: 60px;
  color: black;
  font-size: 1.8rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  
  .mobbtnlog {
  position: absolute;
  top: 40%;
  left: 40%;
  color: black;
  font-size: 1.8rem;
  font-weight: 600;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
.mobbtnlog:hover {
color: #b2d3ff;
transition: 1s;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modal > section {
  width: 90%;
  max-width: 870px;
  height: 640px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  color: black;
  font-size: 1.3rem;
  font-weight: 500;
}
.modal > section > header {
  display: flex;
  position: relative;
  padding: 16px 64px 16px 56px;
  background-color: #3b68ff;
  font-weight: 700;
  color: white;
}

.codd {
  margin-top: 2px;
}

.codee {
  padding-left: 30px;
  padding-top: 3px;
}
.modal > section > header button {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: white;
  background-color: transparent;
}
.modal > section > main {
  display: flex;
  height: 490px;
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  background-color: #f8f9ff;
}

.mide {
  display: flex;
  margin-top: 1px;
  height: 522px;
  width: 434px;
  border-left: 1px solid #dee2e6;;
  margin-left: 434px;
  background-color: #ffffff;
  position: absolute;
}

.midee {
  margin-left: 50px;
  margin-top: 15px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideee {
  margin-left: 30px;
  margin-top: 15px;
  font-size: 1.3rem;
  width: 120px;
}


.mansis {
  padding-bottom: 12px;
  margin-top: 20px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansiis {
  padding-bottom: 12px;
  margin-top: 20px;
  height: 18px;
  width: 230px;
}

.mansi {
  padding-bottom: 12px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansii {
  padding-bottom: 12px;
  height: 18px;
  width: 220px;
}

.mansiip {
  padding-bottom: 12px;
  height: 18px;
  width: 170px;
}

.mideet {
  padding-top: 15px;
  margin-left: 50px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideeet {
  padding-top: 15px;
  margin-left: 30px;
  font-size: 1.3rem;
  width: 120px;
}

.mansit {
  padding-bottom: 6px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  font-size: 1.1rem;
  height: 18px;
}

.mansiit {
  padding-bottom: 6px;
  height: 18px;
  width: 220px;
}

.mansid {
  padding-bottom: 8px;
  padding-top: 4px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
  font-size: 1rem;
}

.status {
  width: 800px;
  height: 400px;
  position: absolute;
  display: flex;
  margin-left: 434px;
  border-radius: 1px solid black;

}



.stepphotoone {
  background-image: url('../assets/의뢰요청.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphototwo {
  background-image: url('../assets/견적산출중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotothree {
  background-image: url('../assets/견적완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofour {
  background-image: url('../assets/진행중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofive {
  background-image: url('../assets/진행완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.statetitle {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 140px;
  position: absolute;
}

.statetitles {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 128px;
  position: absolute;
}

.statetitless {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 155px;
  position: absolute;
}

.states {
  display: flex;
  margin-top: 420px;
}

.stepchilds {
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  background-color: #a5a5a5;
  border-radius: 3px;
}

.childss {
  background-color: #4577e2;
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  border-radius: 3px;
}

.modal > section > footer {
  padding: 12px 16px;
  text-align: right;
}
.modal > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}
.modal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modals {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modals button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modals > section {
  width: 90%;
  max-width: 350px;
  height: 400px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modals > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  font-weight: 700;
}
.modals > section > header button {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modals > section > main {
  padding: 16px;
}
.modals > section > footer {
  margin-top: 250px;
  padding: 12px 16px;
  text-align: left;
  color: #6c757d;;
  font-size: 0.6rem;
}

.introa {
  margin-left: 60px;
}

.introa a{
  color: #4394ff;
  text-decoration: none;
}

.modals > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}


.modals.openModals {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}


@keyframes modals-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modals-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu-trigger {
  margin-right: 40px;
  margin-bottom: 50px;
}
.menu-trigger,
.menu-trigger span {
  display: inline-block;
  transition: all .4s;
  box-sizing: border-box;
}

.menu-trigger {
  position: absolute;
  top: 22%;
  right: 0%;
  width: 50px;
  height: 44px;
}

.menu-trigger span {
  position: absolute;
  left: 0;
  width: 100%;
  height: 6px;
  background-color: #d9efff;
  border-radius: 4px;
}

.menu-trigger span:nth-of-type(1) {
  top: 0;
}

.menu-trigger span:nth-of-type(2) {
  top: 20px;
}

.menu-trigger span:nth-of-type(3) {
  bottom: 0;
}

.menu-trigger.active-1 span:nth-of-type(1) {
  -webkit-transform: translateY (20px) rotate (-45deg);
  transform: translateY(20px) rotate(-45deg);
}

.menu-trigger.active-1 span:nth-of-type(2) {
  opacity: 0;
}

.menu-trigger.active-1 span:nth-of-type(3) {
  -webkit-transform: translateY(-20px) rotate(45deg);
  transform: translateY(-20px) rotate(45deg);
}

.sidebar {
  position: fixed;
  top: 90px;
  right: 0;
  transform: translateX(0%);
  height: 100%;
  width: 300px;
  background-color: #e6efff;
  z-index: 1000;
  transition: transform 0.3s ease-in-out;
}

.sidebar-active {
  transform: translateX(100%);
}



}

@media (min-width: 768px) and (max-width: 991px) {
  body {
    overflow-x: hidden;
  }
  
  ul {
    list-style-type: none;
  }
  
  .headerbody a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: #b2d3ff;
    transition: 1s;
  }
  
  .btn {
    padding: 12px 32px;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: transparent;
    color: white;
    border: 1px solid white;
    cursor: pointer;
  }
  
  .btn-light {
    background: rgba(255,255,255, .2);
  }
  
  .btn:hover {
    background: rgba(255,255,255, .2);
  }
  
  .app_logo {
    position: absolute;
    cursor: pointer;
    width: 120px;
    height: 90px;
    top: 0;
  }

  .app_logoss {
    position: absolute;
    cursor: pointer;
    width: 40%;
    height: 40%;
    top: 16%;
    left: 0;
  }

  .app_logos {
    position: absolute;
    width: 180px;
    height:150px;
    margin-top: 20px;
    margin-left: 69px;
  }


  .idshow {
    position: absolute;
    cursor: pointer;
    top: 23%;
    left: 30%;
  }

  .oas {
    position: absolute;
    width: 130px;
    height:35px;
    margin-top: 200px;
    margin-left: 90px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    background-color: #0d4ba6;
    color: white;
  }

  .oas:hover {
    background-color: #5281c7;
  }
  
  
.headerbody {
  width: 100%;
}

.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
}

.header-bg {
    background-color: #011020;
    transition: .5s;
    height: 90px;
}

.heady {
  position: absolute;
  width: 100%;
  height: 90px;
  left: 20px;
}


.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}

.login {
display: none;
}

.loginbtn {
position: absolute;
right: 0;
margin-right: 50px;
color: white;
top: 35%;
font-size: 1.6rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}

.loginbtn:hover {
color: #b2d3ff;
transition: 1s;
}

.mypa {
display: flex;
position: absolute;
height: 80px;
width: 200px;
right: 10%;
top: 16%;
color: white;
font-size: 1.2rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}

.mypa1 {
position: absolute;
top: 33%;
left: 20%;
margin-right: 60px;
color: white;
font-size: 1.2rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}

.mypa2 {
position: absolute;
top: 33%;
left: 35%;
margin-right: 60px;
color: white;
font-size: 1.2rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}

.mypa3 {
position: absolute;
top: 33%;
left: 50%;
margin-right: 60px;
color: white;
font-size: 1.2rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}


.logout {
position: absolute;
top: 35%;
right: 5%;
color: white;
font-size: 1.2rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}

.logout:hover {
color: #b2d3ff;
transition: 1s;
}


.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modal > section {
  width: 90%;
  max-width: 870px;
  height: 640px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  color: black;
  font-size: 1.3rem;
  font-weight: 500;
}
.modal > section > header {
  display: flex;
  position: relative;
  padding: 16px 64px 16px 56px;
  background-color: #3b68ff;
  font-weight: 700;
  color: white;
}

.codd {
  margin-top: 2px;
}

.codee {
  padding-left: 30px;
  padding-top: 3px;
}
.modal > section > header button {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: white;
  background-color: transparent;
}
.modal > section > main {
  display: flex;
  height: 490px;
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  background-color: #f8f9ff;
}

.mide {
  display: flex;
  margin-top: 1px;
  height: 522px;
  width: 434px;
  border-left: 1px solid #dee2e6;;
  margin-left: 434px;
  background-color: #ffffff;
  position: absolute;
}

.midee {
  margin-left: 50px;
  margin-top: 15px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideee {
  margin-left: 30px;
  margin-top: 15px;
  font-size: 1.3rem;
  width: 120px;
}


.mansis {
  padding-bottom: 12px;
  margin-top: 20px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansiis {
  padding-bottom: 12px;
  margin-top: 20px;
  height: 18px;
  width: 230px;
}

.mansi {
  padding-bottom: 12px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansii {
  padding-bottom: 12px;
  height: 18px;
  width: 220px;
}

.mansiip {
  padding-bottom: 12px;
  height: 18px;
  width: 170px;
}

.mideet {
  padding-top: 15px;
  margin-left: 50px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideeet {
  padding-top: 15px;
  margin-left: 30px;
  font-size: 1.3rem;
  width: 120px;
}

.mansit {
  padding-bottom: 6px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  font-size: 1.1rem;
  height: 18px;
}

.mansiit {
  padding-bottom: 6px;
  height: 18px;
  width: 220px;
}

.mansid {
  padding-bottom: 8px;
  padding-top: 4px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
  font-size: 1rem;
}

.status {
  width: 800px;
  height: 400px;
  position: absolute;
  display: flex;
  margin-left: 434px;
  border-radius: 1px solid black;

}



.stepphotoone {
  background-image: url('../assets/의뢰요청.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphototwo {
  background-image: url('../assets/견적산출중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotothree {
  background-image: url('../assets/견적완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofour {
  background-image: url('../assets/진행중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofive {
  background-image: url('../assets/진행완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.statetitle {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 140px;
  position: absolute;
}

.statetitles {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 128px;
  position: absolute;
}

.statetitless {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 155px;
  position: absolute;
}

.states {
  display: flex;
  margin-top: 420px;
}

.stepchilds {
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  background-color: #a5a5a5;
  border-radius: 3px;
}

.childss {
  background-color: #4577e2;
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  border-radius: 3px;
}

.modal > section > footer {
  padding: 12px 16px;
  text-align: right;
}
.modal > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}
.modal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modals {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modals button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modals > section {
  width: 90%;
  max-width: 350px;
  height: 400px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modals > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  font-weight: 700;
}
.modals > section > header button {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modals > section > main {
  padding: 16px;
}
.modals > section > footer {
  margin-top: 250px;
  padding: 12px 16px;
  text-align: left;
  color: #6c757d;;
  font-size: 0.6rem;
}

.introa {
  margin-left: 60px;
}

.introa a{
  color: #4394ff;
  text-decoration: none;
}

.modals > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}


.modals.openModals {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}


@keyframes modals-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modals-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu-trigger{
  display: none;
}


.mobnav a {
  display: none;
}

.mobbtnpro {
  display: none;
  }
  
  .mobbtnair {
    display: none;
  }
  
  .mobbtnfcl {
    display: none;
  }
  
  .mobbtnlcl {
    display: none;
  }
  
  
  .mobbtnlog {
    display: none;
  }
  

}

@media (min-width: 992px) and (max-width: 1199px) {
  body {
    overflow-x: hidden;
  }

  ul {
    list-style-type: none;
  }
  
  .headerbody a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: #b2d3ff;
    transition: 1s;
  }
  
  .btn {
    padding: 12px 32px;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: transparent;
    color: white;
    border: 1px solid white;
    cursor: pointer;
  }
  
  .btn-light {
    background: rgba(255,255,255, .2);
  }
  
  .btn:hover {
    background: rgba(255,255,255, .2);
  }
  
  .app_logo {
    position: absolute;
    cursor: pointer;
    width: 120px;
    height: 90px;
    top: 0;
  }

  .app_logoss {
    position: absolute;
    cursor: pointer;
    width: 40%;
    height: 40%;
    top: 16%;
    left: 0;
  }

  .app_logos {
    position: absolute;
    width: 180px;
    height:150px;
    margin-top: 20px;
    margin-left: 69px;
  }


  .idshow {
    position: absolute;
    cursor: pointer;
    top: 23%;
    left: 30%;
  }

  .oas {
    position: absolute;
    width: 130px;
    height:35px;
    margin-top: 200px;
    margin-left: 90px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    background-color: #0d4ba6;
    color: white;
  }

  .oas:hover {
    background-color: #5281c7;
  }
  
  
.headerbody {
  width: 100%;
}

.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    margin-top: 42px;
}

.header-bg {
    background-color: #011020;
    transition: .5s;
    height: 90px;
    margin-top: 0px;
}

.heady {
  position: absolute;
  width: 100%;
  height: 90px;
  left: 20px;
}


.nav-menu {
  display: flex;
}

.nav-menu li {
  padding: 0 1rem;
}

.nav-menu a {
  font-size: 1.2rem;
  font-weight: 500;
}

.hamburger {
  display: none;
}

.login {
display: none;
}

.loginbtn {
position: absolute;
right: 0;
margin-right: 50px;
color: white;
top: 35%;
font-size: 1.6rem;
cursor: pointer;
border: none;
outline: none;
background-color: transparent;
}

.loginbtn:hover {
color: #b2d3ff;
transition: 1s;
}

.mypa {
  display: flex;
  position: absolute;
  height: 80px;
  width: 200px;
  right: 10%;
  top: 16%;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  .mypa1 {
  position: absolute;
  top: 33%;
  left: 20%;
  margin-right: 60px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  .mypa2 {
  position: absolute;
  top: 33%;
  left: 35%;
  margin-right: 60px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  .mypa3 {
  position: absolute;
  top: 33%;
  left: 50%;
  margin-right: 60px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
  
  .logout {
  position: absolute;
  top: 35%;
  right: 5%;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  }
  
.logout:hover {
color: #b2d3ff;
transition: 1s;
}


.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modal > section {
  width: 90%;
  max-width: 870px;
  height: 640px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  color: black;
  font-size: 1.3rem;
  font-weight: 500;
}
.modal > section > header {
  display: flex;
  position: relative;
  padding: 16px 64px 16px 56px;
  background-color: #3b68ff;
  font-weight: 700;
  color: white;
}

.codd {
  margin-top: 2px;
}

.codee {
  padding-left: 30px;
  padding-top: 3px;
}
.modal > section > header button {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: white;
  background-color: transparent;
}
.modal > section > main {
  display: flex;
  height: 490px;
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  background-color: #f8f9ff;
}

.mide {
  display: flex;
  margin-top: 1px;
  height: 522px;
  width: 434px;
  border-left: 1px solid #dee2e6;;
  margin-left: 434px;
  background-color: #ffffff;
  position: absolute;
}

.midee {
  margin-left: 50px;
  margin-top: 15px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideee {
  margin-left: 30px;
  margin-top: 15px;
  font-size: 1.3rem;
  width: 120px;
}


.mansis {
  padding-bottom: 12px;
  margin-top: 20px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansiis {
  padding-bottom: 12px;
  margin-top: 20px;
  height: 18px;
  width: 230px;
}

.mansi {
  padding-bottom: 12px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansii {
  padding-bottom: 12px;
  height: 18px;
  width: 220px;
}

.mansiip {
  padding-bottom: 12px;
  height: 18px;
  width: 170px;
}

.mideet {
  padding-top: 15px;
  margin-left: 50px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideeet {
  padding-top: 15px;
  margin-left: 30px;
  font-size: 1.3rem;
  width: 120px;
}

.mansit {
  padding-bottom: 6px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  font-size: 1.1rem;
  height: 18px;
}

.mansiit {
  padding-bottom: 6px;
  height: 18px;
  width: 220px;
}

.mansid {
  padding-bottom: 8px;
  padding-top: 4px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
  font-size: 1rem;
}

.status {
  width: 800px;
  height: 400px;
  position: absolute;
  display: flex;
  margin-left: 434px;
  border-radius: 1px solid black;

}



.stepphotoone {
  background-image: url('../assets/의뢰요청.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphototwo {
  background-image: url('../assets/견적산출중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotothree {
  background-image: url('../assets/견적완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofour {
  background-image: url('../assets/진행중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofive {
  background-image: url('../assets/진행완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.statetitle {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 140px;
  position: absolute;
}

.statetitles {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 128px;
  position: absolute;
}

.statetitless {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 155px;
  position: absolute;
}

.states {
  display: flex;
  margin-top: 420px;
}

.stepchilds {
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  background-color: #a5a5a5;
  border-radius: 3px;
}

.childss {
  background-color: #4577e2;
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  border-radius: 3px;
}

.modal > section > footer {
  padding: 12px 16px;
  text-align: right;
}
.modal > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}
.modal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modals {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modals button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modals > section {
  width: 90%;
  max-width: 350px;
  height: 400px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modals > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  font-weight: 700;
}
.modals > section > header button {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modals > section > main {
  padding: 16px;
}
.modals > section > footer {
  margin-top: 250px;
  padding: 12px 16px;
  text-align: left;
  color: #6c757d;;
  font-size: 0.6rem;
}

.introa {
  margin-left: 60px;
}

.introa a{
  color: #4394ff;
  text-decoration: none;
}

.modals > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}


.modals.openModals {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}


@keyframes modals-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modals-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu-trigger{
  display: none;
}


.mobnav a {
  display: none;
}

.mobbtnpro {
  display: none;
  }
  
  .mobbtnair {
    display: none;
  }
  
  .mobbtnfcl {
    display: none;
  }
  
  .mobbtnlcl {
    display: none;
  }
  
  
  .mobbtnlog {
    display: none;
  }
  

}

@media (min-width: 1200px) {
  body {
    overflow-x: hidden;
  }
  

  .totalwrap {
    width: 100%;
    height: 100%;
    position: absolute;
  }
  
  .full {
    margin: 0 auto;
        position: relative;
        justify-content: center;
        align-items: center;
        background:  radial-gradient(
          circle,
          rgb(17, 23, 78) 2%,
          rgb(14, 24, 56) 7%,
          rgb(11, 19, 43) 10%,
          rgb(9, 9, 24) 30%
        );
    
  }

  .totalwraps {
    width: 1920px;
    position: absolute;
    left: 50%;
    margin-left: -960px;
    
  }
  
  .fulls {
    width: 1920px;
    margin: 0 auto;
        position: relative;
        justify-content: center;
        align-items: center;

    
  }

  ul {
    list-style-type: none;
  }
  
  .headerbody a {
    color: white;
    text-decoration: none;
  }

  a:hover {
    color: #b2d3ff;
    transition: 1s;
  }
  
  .btn {
    padding: 12px 32px;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: transparent;
    color: white;
    border: 1px solid white;
    cursor: pointer;
  }
  
  .btn-light {
    background: rgba(255,255,255, .2);
  }
  
  .btn:hover {
    background: rgba(255,255,255, .2);
  }
  
  .app_logo {
    position: absolute;
    cursor: pointer;
    width: 120px;
    height: 90px;
    top: 0;
  }

  .app_logoss {
    position: absolute;
    cursor: pointer;
    width: 40%;
    height: 40%;
    top: 16%;
    left: 0;
  }

  .app_logos {
    position: absolute;
    width: 180px;
    height:150px;
    margin-top: 20px;
    margin-left: 69px;
  }


  .idshow {
    position: absolute;
    cursor: pointer;
    top: 23%;
    left: 30%;
  }

  .oas {
    position: absolute;
    width: 130px;
    height:35px;
    margin-top: 200px;
    margin-left: 90px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    background-color: #0d4ba6;
    color: white;
  }

  .oas:hover {
    background-color: #5281c7;
  }
  
  
.headerbody {
  width: 100%;
}

.header {
    position: fixed;
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    margin-top: 42px;
}

.header-bg {
    background-color: #011020;
    transition: .5s;
    height: 90px;
    margin-top: 0px;
}

.heady {
  position: absolute;
  width: 1000px;
  height: 90px;
  left: 50%;
  margin-left: -500px;
}


.nav-menu {
    display: flex;
}

.nav-menu li {
    padding: 0 1rem;
}

.nav-menu a {
    font-size: 1.2rem;
    font-weight: 500;
}

.hamburger {
    display: none;
}

.login {
  display: none;
}

.loginbtn {
  position: absolute;
  right: 0;
  color: white;
  top: 35%;
  font-size: 1.6rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.loginbtn:hover {
  color: #b2d3ff;
  transition: 1s;
}

.mypa {
  display: flex;
  position: absolute;
  height: 80px;
  width: 200px;
  right: 5%;
  top: 16%;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.mypa1 {
  position: absolute;
  top: 33%;
  left: 25%;
  margin-right: 60px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.mypa2 {
  position: absolute;
  top: 33%;
  left: 40%;
  margin-right: 60px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.mypa3 {
  position: absolute;
  top: 33%;
  left: 55%;
  margin-right: 60px;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}


.logout {
  position: absolute;
  top: 35%;
  right: 0;
  color: white;
  font-size: 1.2rem;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
}

.logout:hover {
  color: #b2d3ff;
  transition: 1s;
}



.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modal > section {
  width: 90%;
  max-width: 870px;
  height: 640px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
  color: black;
  font-size: 1.3rem;
  font-weight: 500;
}
.modal > section > header {
  display: flex;
  position: relative;
  padding: 16px 64px 16px 56px;
  background-color: #3b68ff;
  font-weight: 700;
  color: white;
}

.codd {
  margin-top: 2px;
}

.codee {
  padding-left: 30px;
  padding-top: 3px;
}
.modal > section > header button {
  position: absolute;
  top: 18px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: white;
  background-color: transparent;
}
.modal > section > main {
  display: flex;
  height: 490px;
  padding: 16px;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
  background-color: #f8f9ff;
}

.mide {
  display: flex;
  margin-top: 1px;
  height: 522px;
  width: 434px;
  border-left: 1px solid #dee2e6;;
  margin-left: 434px;
  background-color: #ffffff;
  position: absolute;
}

.midee {
  margin-left: 50px;
  margin-top: 15px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideee {
  margin-left: 30px;
  margin-top: 15px;
  font-size: 1.3rem;
  width: 120px;
}


.mansis {
  padding-bottom: 12px;
  margin-top: 20px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansiis {
  padding-bottom: 12px;
  margin-top: 20px;
  height: 18px;
  width: 230px;
}

.mansi {
  padding-bottom: 12px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
}

.mansii {
  padding-bottom: 12px;
  height: 18px;
  width: 220px;
}

.mansiip {
  padding-bottom: 12px;
  height: 18px;
  width: 170px;
}

.mideet {
  padding-top: 15px;
  margin-left: 50px;
  text-align: center;
  font-size: 1.3rem;
  width: 120px;
  
}

.mideeet {
  padding-top: 15px;
  margin-left: 30px;
  font-size: 1.3rem;
  width: 120px;
}

.mansit {
  padding-bottom: 6px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  font-size: 1.1rem;
  height: 18px;
}

.mansiit {
  padding-bottom: 6px;
  height: 18px;
  width: 220px;
}

.mansid {
  padding-bottom: 8px;
  padding-top: 4px;
  border-radius: 8px;
  background-color: #272e36;
  color: white;
  height: 18px;
  font-size: 1rem;
}

.status {
  width: 800px;
  height: 400px;
  position: absolute;
  display: flex;
  margin-left: 434px;
  border-radius: 1px solid black;

}



.stepphotoone {
  background-image: url('../assets/의뢰요청.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphototwo {
  background-image: url('../assets/견적산출중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotothree {
  background-image: url('../assets/견적완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofour {
  background-image: url('../assets/진행중.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.stepphotofive {
  background-image: url('../assets/진행완료.svg');
  position: absolute;
  width: 250px;
  height: 250px;
  margin-left: 75px;
  margin-top: 40px;
}

.statetitle {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 140px;
  position: absolute;
}

.statetitles {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 128px;
  position: absolute;
}

.statetitless {
  font-size: 1.8rem;
  font-weight: 800;
  margin-top: 315px;
  margin-left: 155px;
  position: absolute;
}

.states {
  display: flex;
  margin-top: 420px;
}

.stepchilds {
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  background-color: #a5a5a5;
  border-radius: 3px;
}

.childss {
  background-color: #4577e2;
  padding-top: 4px;
  width: 70px;
  height: 23px;
  font-size: 0.9rem;
  margin: 0 5px;
  color: white;
  text-align: center;
  border-radius: 3px;
}

.modal > section > footer {
  padding: 12px 16px;
  text-align: right;
}
.modal > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}
.modal.openModal {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.modals {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  background-color: rgba(0, 0, 0, 0.6);
}
.modals button {
  outline: none;
  cursor: pointer;
  border: 0;
}
.modals > section {
  width: 90%;
  max-width: 350px;
  height: 400px;
  margin: 0 auto;
  border-radius: 0.3rem;
  background-color: #fff;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-show 0.3s;
  overflow: hidden;
}
.modals > section > header {
  position: relative;
  padding: 16px 64px 16px 16px;
  font-weight: 700;
}
.modals > section > header button {
  position: absolute;
  top: 10px;
  right: 15px;
  width: 30px;
  font-size: 21px;
  font-weight: 700;
  text-align: center;
  color: #999;
  background-color: transparent;
}
.modals > section > main {
  padding: 16px;
}
.modals > section > footer {
  margin-top: 250px;
  padding: 12px 16px;
  text-align: left;
  color: #6c757d;;
  font-size: 0.6rem;
}

.introa {
  margin-left: 60px;
}

.introa a{
  color: #4394ff;
  text-decoration: none;
}

.modals > section > footer button {
  padding: 6px 12px;
  color: #fff;
  background-color: #6c757d;
  border-radius: 5px;
  font-size: 13px;
}


.modals.openModals {
  display: flex;
  align-items: center;
  /* 팝업이 열릴때 스르륵 열리는 효과 */
  animation: modal-bg-show 0.3s;
}


@keyframes modals-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modals-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu-trigger{
  display: none;
}


.mobnav a {
  display: none;
}

.mobbtnpro {
  display: none;
  }
  
  .mobbtnair {
    display: none;
  }
  
  .mobbtnfcl {
    display: none;
  }
  
  .mobbtnlcl {
    display: none;
  }
  
  
  .mobbtnlog {
    display: none;
  }
  


}