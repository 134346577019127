.propro {
  cursor: pointer;
}

.propro:hover {
  border: 1px solid rgb(116, 131, 196);
  transition: 0.3s;
}

.reveal {
  position: relative;
  opacity: 0;
}

.reveal.active {
  opacity: 1;
}
.active.fade-bottom {
  animation: fade-bottom 1s ease-in;
}

@keyframes fade-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}






@media (max-width: 767px){
  .section-wrappersa {
    width: 100%;
    height: 650px;
    background-color: #00101f;
  }

  .contens {
    display: none;
  }

  .conten {
    display: none;
  }

  .mobbb {
    display: block;
    color: white;
    font-size: 1.5rem;
    margin-left: 5%;
    font-weight: 500;
  }

  .uls {
    display: none;
  }

  .ulss {
    width: 100%;
    display: block;
    font-size: 3.5rem;
    color: white;
    margin-bottom: 300px;
  }

  .prologo {
    position: absolute;
    width: 80px;
    height: 80px;
    margin-top: 20px;
    left: 30px;
  }

  .proti {
    position: absolute;
    margin-top: 40px;
    left: 110px;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .protro {
    position: absolute;
    margin-top: 40px;
    left: 230px;
    width: 100px;
    text-align: left;
    color: #b2d3ff;
  }

  .propro {
    background-color: rgb(13, 15, 34);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(65, 77, 131);
    max-width: 100vw;
    border-radius: 8px;
    height: 120px;
    font-size: 13px;
    text-align: center;
    align-items: center;
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
  }



}

@media (min-width: 768px) and (max-width: 991px) {
  .section-wrappersa {
    width: 100%;
    height: 700px;
    background-color: #00101f;
  }

  .contens {
    display: none;
  }

  .conten {
    display: none;
  }

  .mobbb {
    display: block;
    color: white;
    font-size: 1.8rem;
    margin-left: 5%;
    font-weight: 600;
  }

  .uls {
    display: none;
  }

  .ulss {
    padding-top: 10px;
    width: 100%;
    display: block;
    font-size: 3.5rem;
    color: white;
    margin-bottom: 300px;
  }
  .prologo {
    position: absolute;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    left: 10%;
  }

  .proti {
    position: absolute;
    margin-top: 45px;
    left: 28%;
    font-size: 1.8rem;
    font-weight: 600;
  }

  .protro {
    position: absolute;
    margin-top: 50px;
    left: 50%;
    width: 270px;
    text-align: left;
    color: #b2d3ff;
  }

  .propro {
    background-color: rgb(13, 15, 34);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(65, 77, 131);
    max-width: 100vw;
    border-radius: 8px;
    height: 130px;
    font-size: 16px;
    text-align: center;
    align-items: center;
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
  }



}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-wrappersa {
    width: 100%;
    height: 750px;
    background-color: #00101f;
  }

  .contens {
    display: none;
  }

  .conten {
    display: none;
  }

  .mobbb {
    display: block;
    color: white;
    font-size: 2.1rem;
    margin-left: 5%;
    font-weight: 600;
  }

  .uls {
    display: none;
  }

  .ulss {
    padding-top: 30px;
    width: 100%;
    display: block;
    font-size: 3.5rem;
    color: white;
    margin-bottom: 300px;
  }
  .prologo {
    position: absolute;
    width: 120px;
    height: 120px;
    margin-top: 10px;
    left: 10%;
  }

  .proti {
    position: absolute;
    margin-top: 50px;
    left: 28%;
    font-size: 2rem;
    font-weight: 600;
  }

  .protro {
    position: absolute;
    margin-top: 55px;
    left: 45%;
    width: 300px;
    text-align: left;
    color: #b2d3ff;
  }

  .propro {
    background-color: rgb(13, 15, 34);
    color: rgb(255, 255, 255);
    border: 1px solid rgb(65, 77, 131);
    max-width: 100vw;
    border-radius: 8px;
    height: 140px;
    font-size: 18px;
    text-align: center;
    align-items: center;
    margin-top: 30px;
    margin-left: 5%;
    margin-right: 5%;
  }




}

@media (min-width: 1200px) {

.section-wrappersa {
  width: 1000px;
    height: 1080px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background:  radial-gradient(
      circle,
      rgb(17, 23, 78) 2%,
      rgb(14, 24, 56) 7%,
      rgb(11, 19, 43) 10%,
      rgb(9, 9, 24) 30%
    );
}


.uls {
  list-style: none;
  padding: 0;
  padding-top: 250px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  width: 1000px;
}

.uls a{
  color:#b2d3ff;
}

.contens {
  position: absolute;
  color: white;
  font-size: 3rem;
  text-align: left;
  top: 17vh;
  left: 0;
}

.mobbb {
  display: none;
}

.conten {
  position: absolute;
  margin-top: 900px;
  padding-right: 300px;
  color: white;
  font-size: 1.4rem;
  text-align: left;
}

.product-1{
  position: relative;
  cursor: pointer;
}

.product-2{
  position: relative;
  cursor: pointer;
}

.product-3{
  position: relative;
  cursor: pointer;
}


.card-1 {
  position: relative;
  width: 300px;
  height: 400px;
  background: #232323;
  border: 1px solid rgb(65, 77, 131);
  border-radius: 20px;
  overflow: hidden;
  z-index: 4;
}
.card-2 {
  position: relative;
  width: 300px;
  height: 400px;
  background: #232323;
  border: 1px solid rgb(65, 77, 131);
  border-radius: 20px;
  overflow: hidden;
  z-index: 4;
}
.card-3 {
  position: relative;
  width: 300px;
  height: 400px;
  background: #232323;
  border: 1px solid rgb(65, 77, 131);
  border-radius: 20px;
  overflow: hidden;
  z-index: 4;
}

.card-1:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../assets/mbn_01.jpg) no-repeat center center/cover;
  transition: .5s ease-in-out;
}

.card-2:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../assets/mbn_02.jpg) no-repeat center center/cover;
  transition: .5s ease-in-out;
}

.card-3:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../assets/mbn_03.jpg) no-repeat center center/cover;
  transition: .5s ease-in-out;
}
.card-1:hover:before {
  background: url(../assets/mbn_on_bg.jpg);
}
.card-2:hover:before {
  background: url(../assets/mbn_on_bg.jpg);
}
.card-3:hover:before {
  background: url(../assets/mbn_on_bg.jpg);
}

.titles {
  position: relative;
  font-family:Arial, Helvetica, sans-serif;
  font-size: 35px;
  font-weight: 600;
  letter-spacing: 1px;
  color: rgb(138, 154, 221);
  margin-top: 12px;
}


.photo {
  position: absolute;
  top: 50%;
  transform: translateY(-40%);
  z-index: 10;
  width: 100%;
  height: 220px;
  transition: .5s;
  
}
.card-1:hover .photo {
  top: 0%;
  transform: translateY(0%);
}
.card-2:hover .photo {
  top: 0%;
  transform: translateY(0%);
}
.card-3:hover .photo {
  top: 0%;
  transform: translateY(0%);
}

.photo img {
  position: absolute;
  width: 220px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-25deg);
  transition: .5s;
  
}

.card-1:hover .photo img {
  width: 260px;
  top: 56%;
}

.card-2:hover .photo img {
  width: 260px;
  top: 56%;
}

.card-3:hover .photo img {
  width: 260px;
  top: 56%;
}

.content {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100px;
  text-align: center;
  transition: 1s;
  z-index: 5;
}
.card-1:hover .content {
  height: 190px;
}
.card-2:hover .content {
  height: 190px;
}
.card-3:hover .content {
  height: 190px;
}

.features {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 20px;
  transition: .5s;
  visibility: hidden;
  opacity: 0;
}

.card-1:hover .features {
  visibility: visible;
  opacity: 1;
  transition-delay: .5s;
  transform: translateY(-100px);
}
.card-2:hover .features {
  visibility: visible;
  opacity: 1;
  transition-delay: .5s;
  transform: translateY(-100px);
}
.card-3:hover .features {
  visibility: visible;
  opacity: 1;
  transition-delay: .5s;
  transform: translateY(-100px);
}

.card-1:hover .titles {
  transform: translateY(-120px);
}

.card-2:hover .titles {
  transform: translateY(-120px);
}

.card-3:hover .titles {
  transform: translateY(-120px);
}


.feature h3 {
  color: #fff;
  font-weight: 450;
  font-size: 18px;
  font-family: Arial, Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-right: 10px;
}
.feature span {
  min-width: 20px;
  padding: 0 4px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  font-size: 14px;
  display: inline-block;
  color: #111;
  background: #fff;
  margin: 0 5px;
  transition: .4s;
  border-radius: 4px;
  cursor: pointer
}

.feature span:hover {
  color: #000;
  background: var(--product-color);
}

.btn-buy {
  display:inline-block;
  padding: 10px 20px;
  background: transparent;
  border: 1px solid rgb(138, 154, 221);
  border-radius: 4px;
  margin-top: 10px;
  text-decoration: none;
  font-weight: 600;
  color: white;
  opacity: 1;
  transform: translateY(50px);
  transition: 0.5s;
  cursor: pointer;
  outline:none;
}
.card-1:hover .btn-buy {
  opacity: 1;
  transform: translateY(-90px);
  transition: 0.5s;
  transition-delay: 0.5s;
}
.card-2:hover .btn-buy {
  opacity: 1;
  transform: translateY(-90px);
  transition: 0.5s;
  transition-delay: 0.5s;
}
.card-3:hover .btn-buy {
  opacity: 1;
  transform: translateY(-90px);
  transition: 0.5s;
  transition-delay: 0.5s;
}

.card-1:hover .btn-buy:hover {
  background: var(--product-color);
  transition-delay: 0;
  transition: 0.05s;
  background-color: rgba(155, 155, 155, 0.5);
}
.card-2:hover .btn-buy:hover {
  background: var(--product-color);
  transition-delay: 0;
  transition: 0.05s;
  background-color: rgba(155, 155, 155, 0.5);
}
.card-3:hover .btn-buy:hover {
  background: var(--product-color);
  transition-delay: 0;
  transition: 0.05s;
  background-color: rgba(155, 155, 155, 0.5);
}

.mob-title {
  display: none;
}

.ulss {
  display: none;
}

}