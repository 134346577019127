
@media (max-width: 767px){

    .footer {
        width: 100%;
        height: 240px;
        background: #000b15;
        bottom: 0;
    }
    
    .footer-container {
        width: 100%;
        margin: auto;
        display: grid;
        padding-left: 30px;
    }
    
    .footer .left {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
    }
    
    .footer .right {
            display: none;
    }
    
    
    .footer p {
        width: 320px;
        font-size: 0.8rem;
        color: rgb(170, 170, 170);
    }
    
    .footer .location {
        display: flex;
        align-items: center;
    }
    
    .f__line {
        border-top: 1px solid #b2d3ff;
    }
    
    .footer .location h4 {
        padding-top: 0;
    }
    
    
    .fot {
        position: absolute;
        width: 300px;
        margin-top: 20px;
        display: flex;
        font-size: 0.8rem;
        color: rgb(170, 170, 170);
    }
    
    .fot-ti a{
        cursor: pointer;
        color: rgb(170, 170, 170);
        padding-right: 10px;
        text-decoration: none;
    }
    
    .fot-te a{
        cursor: pointer;
        color: rgb(170, 170, 170);
        padding-left: 10px;
        text-decoration: none;
    }
    
    .footgo {
        cursor: pointer;
    }
    
}

@media (min-width: 768px) and (max-width: 991px) {

    .footer {
        width: 100%;
        height: 240px;
        background: #000b15;
        bottom: 0;
    }
    
    .footer-container {
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
        margin-left: 30px;
    }
    
    .footer .left {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
    }
    
    .footer .right {
            display: flex;
            flex-direction: column;
            margin-top: 100px;
            margin-left: 50%;
    }
    
    
    .footer p {
        font-size: 0.8rem;
        color: rgb(170, 170, 170);
    }
    
    .footer .location {
        display: flex;
        align-items: center;
    }
    
    .f__line {
        border-top: 1px solid #b2d3ff;
    }
    
    .footer .location h4 {
        padding-top: 0;
    }
    
    .foologo{
        width: 160px;
        height: 80px;
    }
    
    .fot {
        position: absolute;
        width: 300px;
        margin-top: 20px;
        display: flex;
        font-size: 0.8rem;
        color: rgb(170, 170, 170);
    }
    
    .fot-ti a{
        cursor: pointer;
        color: rgb(170, 170, 170);
        padding-right: 10px;
        text-decoration: none;
    }
    
    .fot-te a{
        cursor: pointer;
        color: rgb(170, 170, 170);
        padding-left: 10px;
        text-decoration: none;
    }
    
    .footgo {
        cursor: pointer;
    }
    

}

@media (min-width: 992px) and (max-width: 1199px) {

    .footer {
        width: 100%;
        height: 240px;
        background: #000b15;
        bottom: 0;
    }
    
    .footer-container {
        width: 100%;
        margin: auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 40px;
        padding-left: 60px;
    }
    
    .footer .left {
        display: flex;
        flex-direction: column;
        margin-top: 70px;
    }
    
    .footer .right {
            display: flex;
            flex-direction: column;
            margin-top: 100px;
            margin-left: 50%;
    }
    
    
    .footer p {
        font-size: 0.8rem;
        color: rgb(170, 170, 170);
    }
    
    .footer .location {
        display: flex;
        align-items: center;
    }
    
    .f__line {
        border-top: 1px solid #b2d3ff;
    }
    
    .footer .location h4 {
        padding-top: 0;
    }
    
    .foologo{
        width: 160px;
        height: 80px;
    }
    
    .fot {
        position: absolute;
        width: 300px;
        margin-top: 20px;
        display: flex;
        font-size: 0.8rem;
        color: rgb(170, 170, 170);
    }
    
    .fot-ti a{
        cursor: pointer;
        color: rgb(170, 170, 170);
        padding-right: 10px;
        text-decoration: none;
    }
    
    .fot-te a{
        cursor: pointer;
        color: rgb(170, 170, 170);
        padding-left: 10px;
        text-decoration: none;
    }
    
    .footgo {
        cursor: pointer;
    }
    

}

@media (min-width: 1200px) {

.footer {
    width: 100%;
    height: 240px;
    background: #000b15;
    bottom: 0;
}

.footer-container {
    width: 1000PX;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 40px;
}

.footer .left {
    display: flex;
    flex-direction: column;
    margin-top: 70px;
}

.footer .right {
        display: flex;
        flex-direction: column;
        margin-top: 100px;
        margin-left: 320px;
}


.footer p {
    font-size: 0.8rem;
    color: rgb(170, 170, 170);
}

.footer .location {
    display: flex;
    align-items: center;
}

.f__line {
    border-top: 1px solid #b2d3ff;
}

.footer .location h4 {
    padding-top: 0;
}

.foologo{
    width: 160px;
    height: 80px;
}

.fot {
    position: absolute;
    width: 300px;
    margin-top: 20px;
    display: flex;
    font-size: 0.8rem;
    color: rgb(170, 170, 170);
}

.fot-ti a{
    cursor: pointer;
    color: rgb(170, 170, 170);
    padding-right: 10px;
    text-decoration: none;
}

.fot-te a{
    cursor: pointer;
    color: rgb(170, 170, 170);
    padding-left: 10px;
    text-decoration: none;
}

.footgo {
    cursor: pointer;
}

}