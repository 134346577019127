

@media (max-width: 767px){

  .final {
    width: 100%;
    height: 650px;
    background: rgb(9, 9, 24);
    }
    
    .family a {
      text-decoration: none;
    }
    
    
    .section-wrapperss {
    justify-content: center;
    align-items: center;
    }
    
    .family {
      padding-top: 100px;
      width: 100%;
      display: block;
      font-size: 1.4rem;
      color: white;
    }

    .family p{
      position: absolute;
      left: 5%;
      font-size: 1.6rem;
      font-weight: 600;
    }
    
    .jungdari {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      max-width: 100vw;
      border-radius: 8px;
      height: 120px;
      font-size: 20px;
      text-align: center;
      align-items: center;
      margin-top: 80px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .darlidata {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      max-width: 100vw;
      border-radius: 8px;
      height: 120px;
      font-size: 20px;
      text-align: center;
      align-items: center;
      margin-top: 40px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .familylogo {
      position: absolute;
      width: 130px;
      height: 150px;
      margin-top: 50px;
      left: 10%;
    }
    
    .intro {
      position: absolute;
      margin-top: 40px;
      left: 190px;
      width: 140px;
      text-align: left;
      color: rgb(184, 194, 233);
      font-size: 9px;
    }

}

@media (min-width: 768px) and (max-width: 991px) {
  .final {
    width: 100%;
    height: 700px;
    background: rgb(9, 9, 24);
    }
    
    .family a {
      text-decoration: none;
    }
    
    
    .section-wrapperss {
    justify-content: center;
    align-items: center;
    }
    
    .family {
      padding-top: 100px;
      width: 100%;
      display: block;
      font-size: 1.4rem;
      color: white;
    }

    .family p{
      position: absolute;
      left: 5%;
      font-size: 1.6rem;
      font-weight: 600;
    }
    
    .jungdari {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      max-width: 100vw;
      border-radius: 8px;
      height: 120px;
      font-size: 20px;
      text-align: center;
      align-items: center;
      margin-top: 80px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .darlidata {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      max-width: 100vw;
      border-radius: 8px;
      height: 120px;
      font-size: 20px;
      text-align: center;
      align-items: center;
      margin-top: 40px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .familylogo {
      position: absolute;
      width: 180px;
      height: 180px;
      margin-top: 40px;
      left: 13%;
    }
    
    .intro {
      position: absolute;
      margin-top: 25px;
      left: 50%;
      width: 290px;
      text-align: left;
      color: rgb(184, 194, 233);
      font-size: 13px;
    }

}

@media (min-width: 992px) and (max-width: 1199px) {
  .final {
    width: 100%;
    height: 750px;
    background: rgb(9, 9, 24);
    }
    
    .family a {
      text-decoration: none;
    }
    
    
    .section-wrapperss {
    justify-content: center;
    align-items: center;
    }
    
    .family {
      padding-top: 100px;
      width: 100%;
      display: block;
      font-size: 1.4rem;
      color: white;
    }

    .family p{
      position: absolute;
      left: 5%;
      font-size: 1.6rem;
      font-weight: 600;
    }
    
    .jungdari {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      max-width: 100vw;
      border-radius: 8px;
      height: 120px;
      font-size: 20px;
      text-align: center;
      align-items: center;
      margin-top: 80px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .darlidata {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      max-width: 100vw;
      border-radius: 8px;
      height: 120px;
      font-size: 20px;
      text-align: center;
      align-items: center;
      margin-top: 40px;
      margin-left: 5%;
      margin-right: 5%;
    }
    
    .familylogo {
      position: absolute;
      width: 200px;
      height: 200px;
      margin-top: 40px;
      left: 15%;
    }
    
    .intro {
      position: absolute;
      margin-top: 35px;
      left: 45%;
      width: 430px;
      text-align: left;
      color: rgb(184, 194, 233);
      font-size: 15px;
    }


}

@media (min-width: 1200px) {
  .final {
    padding-top: 850px;
    width: 1000px;
    height: 900px;
    background: rgb(9, 9, 24);
    }
    
    .family a {
      text-decoration: none;
    }
    
    
    .section-wrapperss {
    justify-content: center;
    align-items: center;
    }
    
    .family {
      padding-top: 250px;
      width: 1000px;
      display: block;
      font-size: 3.5rem;
      color: white;
      margin-bottom: 300px;
    }
    
    .jungdari {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      width: 100%;
      border-radius: 8px;
      height: 140px;
      font-size: 20px;
      text-align: center;
      align-items: center;
      margin-top: 60px;
    }
    
    .darlidata {
      background-color: rgb(13, 15, 34);
      color: rgb(255, 255, 255);
      border: 1px solid rgb(65, 77, 131);
      width: 100%;
      border-radius: 8px;
      height: 140px;
      font-size: 20px;
      margin-top: 40px;
    }
    
    .familylogo {
      width: 240px;
      height: 80px;
      margin-top: 5%;
      margin-left: 8%;
    }
    
    .intro {
      color: rgb(184, 194, 233);
      font-size: 1.1rem;
      text-align: left;
      margin-left: 400px;
      margin-top: -9%;
    }
}